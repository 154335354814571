<template>
  <div class="card-header" v-loading="loading">
    <el-row
      v-for="element in $companyConfiguration.card.getCardHeaderElements()"
      :key="element.label"
      type="flex"
      class="card-header-element"
    >
      <div class="card-header-element-icon">
        <i :class="[element.icon]" />
      </div>
      <div class="card-header-element-text">
        <div class="card-header-element-text-header el-tag--plain el-tag--info">{{element.label}}</div>
        <div class="card-header-element-text-body">
          <div v-if="element.value">{{ element.value }}</div>
          <div v-if="element.prop"> {{ $companyConfiguration.helpers.getObjectPropByString(card, element.prop) }}</div>
        </div>
      </div>
    </el-row>

  </div>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "card-header-from-props",
  components: {},

  props: {},

  watch: {},
  computed: {
    ...mapGetters('card', {
      card: 'card',
      loading: 'loading',
    }),
  },
  data() {
    return {
      thisComponent: this,
    }
  },
  mounted() {
  },
  methods: {

  }
}
</script>


<style lang="scss">

.card-header {
  display: flex;
  gap: 3rem;
  justify-content: space-between;

  &-element {
    .card-header-element-icon {
      margin-right:6px
    }

    i {
      padding: 12px;
      background-color: #F5F7FA;
      color: #8F9298;
      border-radius: 0;
    }
  }
}

</style>